import React, { useEffect, useState } from 'react'
import FormValidation from '../service/FormValidation'
import { useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/bootstrap.css'
// import Swal from 'sweetalert2'
import '../pages/style.css'
import URL from '../service/API_URL'
import { Typeahead } from "react-bootstrap-typeahead"

const PlacementHome = () => {
  const [data, setData] = useState({})
  const [formError, setFormError] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [kabkota, setKabKota] = useState([])
  const [provinsi, setProvinsi] = useState([])
  const [sekolah, setSekolah] = useState([])
  const [jenjang, setJenjang] = useState([])
  const [area, setArea] = useState([])
  const [id_provinsi, setIdProvinsi] = useState('')
  const [code_jenjang, setCodeJenjang] = useState('')
  const [kode_sekolah, setCodeSekolah] = useState('')
  const [id_area, setIdArea] = useState('')
  const navigate = useNavigate()
  

  const handleChange = (e) =>{
    const {name, value} = e.target;
    setData({...data, [name]: value})
  }

  const submitForm = () => {
    setLoading(true)
    URL.API_URL.post('register', data)
    .then(res => {
      setLoading(false)
      navigate('placement-test', {state: res.data.data})
    })
    .catch(err => console.log(err))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formError);

    setFormError(FormValidation(data))
    setIsSubmit(true)
  }

  useEffect(()=>{
    if (Object.keys(formError).length === 0 && isSubmit) {
        submitForm();
    };
    getProvinsi();
    getJenjang();
    getArea();
  },[formError])


    const getProvinsi = async () => {
        await URL.urlApplication.get('master/provinces')
            .then(response => response.data.data)
            .then(result => {
                setProvinsi(result)
            })
            .catch(error => console.log(error))
    }

    const getJenjang = async () => {
        await URL.urlApplication.get('master/jenjang')
            .then(response => response.data.data)
            .then(result => {
                setJenjang(result)
            })
            .catch(error => console.log(error))
    }

    const getArea = async () => {
      await URL.urlApplication.get('master/area')
          .then(response => response.data.data)
          .then(result => {
              setArea(result)
          })
          .catch(error => console.log(error))
    }

    const pilihProvinsi = async (e) => {
        setIdProvinsi(e.target.value);
    }

    const pilihArea = async (e) => {
      setIdArea(e.target.value);
      const {name, value} = e.target;
      setData({...data, [name]: value})
    }

    const pilihSekolahJenjang = async (e) => {
        await URL.urlApplication.get('sekolah-indonesia?province_id=' + id_provinsi + '&jenjang=' + e.target.value)
            .then(response => response.data.data)
            .then(result => {
                setSekolah(result)
            })
            .catch(error => console.log(error))
    }

    const changeSekolah = (kode, e) => {
      if (kode.length > 0) {
       setCodeSekolah(kode[0].kode_sekolah);
       setData({ ...data, 'kode_sekolah':kode[0].kode_sekolah });
      } else {
          setCodeSekolah('');
      }

      if (e && e.target) {
          const { name, value } = e.target;
          if (name) {
              setData({ ...data, [name]: value });
          }
      }
    };

  return (
    <>
    <div className="wrapper-login">
      <div className="header-login">
        <p>NIEC English Course</p>
        <h1>Placement Test</h1>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center">
            <img className='login-img' width={500} src="/assets/img/login-img.png" alt="" />
          </div>
          <div className="col-lg-6 d-flex align-item-center">
          <div className="form">
              <h2 className='text-center text-label'>Register</h2>
              <form action="" onSubmit={handleSubmit}>
                <div className="form-input mb-3">
                  <label htmlFor="name" className="form-label text-label">Name<span className='text-primary'>*</span></label>
                  <input name='name' className={`form-control ${formError.name && "is-invalid"}`} type="text" placeholder="Fullname" onChange={handleChange} />
                  { formError.name && (<div className="text-invalid mb-3">{formError.name}</div>)}
                </div>
                <div className="form-input mb-3">
                  <label htmlFor="name" className="form-label text-label">Email<span className='text-primary'>*</span></label>
                  <input name='email' className={`form-control ${formError.email && "is-invalid"}`} type="email" placeholder="Email" onChange={handleChange} />
                  { formError.email && (<div className="text-invalid mb-3">{formError.email}</div>)}
                </div>

                <div className="form-input mb-3">
                  <label htmlFor="phone" className="form-label text-label">Phone<span className='text-primary'>*</span></label>
                  <PhoneInput 
                    inputProps={{
                      // name: 'phone',
                      required: true,
                    }}
                    inputClass={`${formError.phone && "is-invalid"}`}
                    inputStyle={{width:'100%', height: '10px'}}
                    country={'id'}
                    onChange={phone => setData({...data, phone: phone})}
                    />
                    { formError.phone && (<div className="text-invalid mb-3">{formError.phone}</div>)}
                </div>

                <div className="mb-3">
                    <label htmlFor="area" className="form-label text-label">Area<span className='text-primary'>*</span></label>
                    <select defaultValue={id_area} name='id_area' value={id_area} className={`form-control`}  onChange={(e) => pilihArea(e)} required>
                      <option value='' >Area</option>
                        {
                          area?.length > 0 ?
                            area.map((area, i) => {
                              return <option key={i} value={area.id_area}>{area.area}</option>
                            })
                            :
                            ''
                        }
                      </select>
                      {formError.provinsi_sekolah?.message && (<div className="invalid-feedback">{formError.provinsi_sekolah?.message}</div>)}
                </div>

                <div className="mb-3">
                <label htmlFor="phone" className="form-label text-label">Pilih Provinsi<span className='text-primary'>*</span></label>
                  <select defaultValue={''} className={`form-control`} onChange={(e) => pilihProvinsi(e)} required>
                    <option value=''>Pilih Provinsi</option>
                      {
                        provinsi?.length > 0 ?
                          provinsi.map((prov, i) => {
                            return <option key={i} value={prov.id}>{prov.province_name}</option>
                          })
                          :
                          ''
                       }
                  </select>
                  {formError.provinsi_sekolah?.message && (<div className="invalid-feedback">{formError.provinsi_sekolah?.message}</div>)}
                </div>
                                
                <div className="mb-3">
                <label htmlFor="phone" className="form-label text-label">Pilih Jenjang<span className='text-primary'>*</span></label>
                  <select defaultValue={''} className={`form-control`} onChange={(e) => pilihSekolahJenjang(e)} required>
                      <option value='' selected disabled hidden>Pilih Jenjang</option>
                        {
                          jenjang?.length > 0 ?
                            jenjang.map((jenjang, i) => {
                               return <option key={i} value={jenjang.code_jenjang}>{jenjang.jenjang_pendidikan}</option>
                            })
                            :
                          ''
                        }
                  </select>
                  {formError.provinsi_sekolah?.message && (<div className="invalid-feedback">{formError.provinsi_sekolah?.message}</div>)}
                </div>
                
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label text-label">Pilih Sekolah<span className='text-primary'>*</span></label>
                    {/* <select className={`form-control ${formError.sekolah?.message && 'is-invalid'}`} required>
                      <option value='' selected disabled hidden>Pilih Sekolah</option>
                        {
                          sekolah?.length > 0 ? 
                          sekolah.map((skull, i) => {
                            return <option key={i} value={skull.kode_sekolah}>{skull.nama_sekolah}</option>
                          })
                          :
                          ''
                        }
                    </select> */}
                    <Typeahead name='kode_sekolah'
                      id='basic-typeahead'
                      labelKey='nama_sekolah'
                      options={sekolah}
                      onChange={changeSekolah}
                      placeholder='Pilih Sekolah'
                      />
                    {formError.sekolah?.message && (<div className="invalid-feedback">{formError.sekolah?.message}</div>)}
                </div>

                <div className="form-input mb-3">
                  {/* <label htmlFor="phone" className="form-label text-label">Phone</label>
                  <input name='phone' className={`form-control ${formError.phone && "is-invalid"}`} type="tel" placeholder="Phone" onChange={handleChange} />
                  { formError.phone && (<div className="invalid-feedback mb-3">{formError.phone}</div>)} */}
                </div>
    
                <div className="text-center">
                  <button disabled={loading ? true : false} type='submit' className='btn text-center btn-regis'>Register</button>
                </div>
              </form>
          </div>
          </div>
        </div>
      </div>        
    </div>
    </>
  )
}

export default PlacementHome