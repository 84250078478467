import React, { useEffect, useState } from 'react'
import axios from 'axios'
import StructureQuestion from '../components/questions/StructureQuestion';
import ReadingQuestion from '../components/questions/ReadingQuestion';
import ListeningQuestion from '../components/questions/ListeningQuestion';
import Swal from 'sweetalert2'
import '../pages/style.css'
import { useLocation, useNavigate } from 'react-router-dom';
import WritingQuestion from '../components/questions/WritingQuestion';
import URL from '../service/API_URL'


export const PlacementTest = () => {
    const location = useLocation()
    const navigate = useNavigate()
    
    const [structureQuestion, setStructureQuestion] = useState([]);
    const [structureComplete1, setStructureComplete1] = useState([]);
    const [structureWrong, setStructureWrong] = useState([]);
    const [structureComplete2, setStructureComplete2] = useState([]);
    // ------------------------------------------------------------//
    const [readingQuestion, setReadingQuestion] = useState([]);
    const [listeningQuestion, setListeningQuestion] = useState([]);
    const [arrangeQuestion, setArrangeQuestion] = useState([]);
    const [writingQuestion, setWritingQuestion] = useState([]);
    // ------------------------------------------------------------//
    const [structureAnswer, setStructureAnswer] = useState([]);
    const [readingAnswer, setReadingAnswer] = useState([]);
    const [listeningAnswer, setListeningAnswer] = useState([]);
    const [arrangeAnswer, setArrangeAnswer ] = useState([]);
    const [writingAnswer, setWritingAnswer ] = useState([]);
    // ------------------------------------------------------------//
    const [showStructure, setShowStructure] = useState(true);
    const [showReading, setShowReading] = useState(false);
    const [showListening, setShowListening] = useState(false);
    const [showWriting, setShowWriting] = useState(false);
    // ------------------------------------------------------------//
    const [loading, setLoading] = useState(false)
    // ------------------------------------------------------------//
    // console.log(location);
    const user = location?.state;
    const id_user = location?.state?.id;
    const token = 'x9UMxaGolsflds3yKHiQ7i04whX1WhLOK0KxIDMz3ThQtvDsFpQgpUwTLRUTXKbXgH';
    // ------------------------------------------------------------//
    const [isMobile, setIsMobile] = useState(false)
 
    //choose the screen size 
    const handleResize = () => {
        if (window.innerWidth < 769) {
            setIsMobile(true)
            // setShowStructure(true)
            // setShowListening(true)
            // setShowReading(true)
            // setShowWriting(true)
        } else {
            setIsMobile(false)
        }
    }
    useEffect(()=>{
        handleResize()
        window.addEventListener("resize", handleResize)
    })

    useEffect(()=>{
        if (!user) {
            navigate('/');
        }
    })

    const handleShowStructure = () => {
        setShowStructure(true)
        setShowReading(false)
        setShowListening(false)
        setShowWriting(false)
    }
    const handleShowReading = () => {
        setShowStructure(false)
        setShowReading(true)
        setShowListening(false)
        setShowWriting(false)
    }
    const handleShowListening = () => {
        setShowStructure(false)
        setShowReading(false)
        setShowListening(true)
        setShowWriting(false)
    }
    const handleShowWriting = () => {
        setShowStructure(false)
        setShowReading(false)
        setShowListening(false)
        setShowWriting(true)
    }

    const handleStructureAnswers = (e, correct_answer, i) => {
        let _answer = [...structureAnswer]
        _answer[i].option = e.target.value
        _answer[i].correct_answer = correct_answer
        _answer.push()
        console.log(_answer)
    }

    const handleReadingAnswers = (e, correct_answer ,k) => {
        let _answer = [...readingAnswer]
        _answer[k].option = e.target.value
        _answer[k].correct_answer = correct_answer
        _answer.push()
        // console.log(_answer);
    }

    const handleListeningAnswers = (e, correct_answer, k) => {
        let _answer = [...listeningAnswer]
        _answer[k].option = e.target.value
        _answer[k].correct_answer = correct_answer
        _answer.push()
        // console.log(_answer);
    }

    const handleWritingAnswer = (e) => {
        let _answer = {...writingAnswer, index:1, option: e, correct_answer: 'null'}
        setWritingAnswer(_answer);
        // console.log(_answer);
    }

    const handleClick = () => {
        setLoading(true)
        let final_answer = [];
        let score = [];

        let point1 = 0;
        for (let i = 0; i < structureQuestion.length; i++){
            if (structureAnswer[i]?.option === structureQuestion[i]?.correct_answer ){
                point1++
            }
        }
        let point2 = 0;
        for (let i = 0; i < readingAnswer.length; i++) {
            if (readingAnswer[i].correct_answer === readingAnswer[i].option) {
                point2++
            }
        }
        let point3 = 0;
        for (let i = 0; i < listeningAnswer.length; i++) {
            if (listeningAnswer[i].correct_answer === listeningAnswer[i].option) {
                point3++
            }
        }
        let point4 = 0
        for (let i = 0; i < arrangeQuestion.length; i++) {
            if (arrangeAnswer[i].text_arrange === arrangeQuestion[i].text_arrange) {
                point4++
            }
        }
        score.push({
            structureScore: point1,
            readingScore: point2 + point4,
            listeningScore: point3,
            id_user: id_user,
            countStructure: structureAnswer.length,
            countReading: readingAnswer.length + arrangeQuestion.length,
            countListening: listeningAnswer.length,
        })

        for (let i = 0; i < structureAnswer.length; i++) {
            final_answer.push({'option':structureAnswer[i].option, 'correct_answer': structureAnswer[i].correct_answer, 'id_user': structureAnswer[i].id_user, 'id_question': structureAnswer[i].id_question})            
        }
        for (let i = 0; i < readingAnswer.length; i++) {
            final_answer.push({'option':readingAnswer[i].option, 'correct_answer': readingAnswer[i].correct_answer, 'id_user': readingAnswer[i].id_user, 'id_question': readingAnswer[i].id_question})            
        }
        for (let i = 0; i < listeningAnswer.length; i++) {
            final_answer.push({'option':listeningAnswer[i].option, 'correct_answer': listeningAnswer[i].correct_answer, 'id_user': listeningAnswer[i].id_user, 'id_question': listeningAnswer[i].id_question})            
        }
        
        final_answer.push({'option':writingAnswer.option, 'correct_answer': writingAnswer.correct_answer, 'id_user': writingAnswer.id_user, 'id_question': writingAnswer.id_question})        

        

        // console.log('score',score); 
        // console.log(writingAnswer)
        // console.log('final_answer',final_answer);
        // setLoading(false)
        URL.API_URL.post('storeAnswers',final_answer)
        .then(
            URL.API_URL.post('storeScore',score)
            .catch(err => console.log(err))
        )
        .then(res => {
            setLoading(false)
            Swal.fire({
                icon: 'success',
                title: 'Jawaban Berhasil Disubmit',
                text: 'Silahkan Cek Hasil Test di Email Anda',
                confirmButtonText: 'Ok',
            }).then((result)=>{
                // setIsfinish(true);
                navigate('/', {});
            })
        })
    }
    
    useEffect(() => {
        getStructureQuestion();
        getReadingQuestion();
        getListeningQuestion();
        getArrangeQuestion();
    }, []) 
    
    const getStructureQuestion = () => {
      URL.API_URL.get('question')
        .then(res => { 
            const data = res.data.data
            const completeSentenceQuestion1 = []
            const wrongSentenceQuestion = []
            const completeSentenceQuestion2 = []
            const writingQuestion = [];
            let tempStructure = [];
            let tempWriting = [];
                         
            for (let j = 0; j < data.length; j++) {
                const answers = [data[j].opt_a, data[j].opt_b, data[j].opt_c, data[j].opt_d]
                const newAnswers = answers.slice()
                for (let i = newAnswers.length - 1; i > 0; i--) {
                    const rand = Math.floor(Math.random() * (i + 1));
                    [newAnswers[i], newAnswers[rand]] = [newAnswers[rand], newAnswers[i]];
                }
                if (data[j].id_question_type === 1) {
                    tempStructure.push({'index': j + 1,'option':'No Answer', 'correct_answer': data[j].opt_a, 'id_user': id_user, 'id_question': data[j].id});
                    if (data[j].id_structure_type === 1) {
                        completeSentenceQuestion1.push({
                            id: data[j].id,
                            question: data[j].question,
                            answers: newAnswers,
                            question_type: data[j].tb_question_type?.question_type,
                            correct_answer: data[j].opt_a
                        })
                    }else if(data[j].id_structure_type === 2){
                        wrongSentenceQuestion.push({
                            id: data[j].id,
                            question: data[j].question,
                            answers: newAnswers,
                            question_type: data[j].tb_question_type?.question_type,
                            correct_answer: data[j].opt_a
                        })
                    }else if(data[j].id_structure_type === 3){
                        completeSentenceQuestion2.push({
                            id: data[j].id,
                            question: data[j].question,
                            answers: newAnswers,
                            question_type: data[j].tb_question_type?.question_type,
                            correct_answer: data[j].opt_a
                        })
                    }
                }
                
                if (data[j].id_question_type === 4) {
                    tempWriting.push({
                        index: j + 1,
                        option: 'No Answer',
                        correct_answer: 'null', 
                        id_user: id_user, id_question: data[j].id
                    })
                    writingQuestion.push({
                        id: data[j].id,
                        question: data[j].question,
                        question_type: data[j].id_question_type,
                    })
                }
            }
            const rand = Math.floor(Math.random() * writingQuestion.length)

            setStructureAnswer(tempStructure);
            setStructureQuestion(tempStructure);
            setStructureComplete1(completeSentenceQuestion1);
            setStructureWrong(wrongSentenceQuestion);
            setStructureComplete2(completeSentenceQuestion2);
            setWritingAnswer(tempWriting[rand])
            setWritingQuestion(writingQuestion[rand].question);
        })
        .catch(err => console.log(err))
    }

    const getReadingQuestion = () => {
        URL.API_URL.get('readingQuestion')
        .then(res => {
            const data = res.data.data;
            let tempReading = []
            let o = 1
            for (let j = 0; j < data.length; j++) {
                const question = data[j].tb_question;
                for (let i = 0; i < question.length; i++) {
                    tempReading.push({'index': o++ ,'option':'No Answer', 'correct_answer': data[j].tb_question[i].opt_a, 'id_user': id_user, 'id_question': data[j].tb_question[i].id});
                    const answer = [data[j].tb_question[i].opt_a, data[j].tb_question[i].opt_b, data[j].tb_question[i].opt_c, data[j].tb_question[i].opt_d]
                    const newAnswer = answer.slice()
                    for (let k = newAnswer.length - 1; k > 0; k--) {
                        const rand = Math.floor(Math.random() * (k + 1));
                        [newAnswer[k], newAnswer[rand]] = [newAnswer[rand], newAnswer[k]];
                    }
                    data[j].tb_question[i].answer = newAnswer;
                    data[j].tb_question[i].correct_answer = data[j].tb_question[i].opt_a;
                };
            }
            setReadingAnswer(tempReading)
            setReadingQuestion(data); 
        })
        .catch(err => console.log(err))
    }

    const getListeningQuestion = () => {
        URL.API_URL.get('listeningQuestion')
        .then(res => {
            const data = res.data.data;
            let tempListening = []
            let o = 1;
            for (let j = 0; j < data.length; j++) {
                const question = data[j].tb_question;
                for (let i = 0; i < question.length; i++) {
                    tempListening.push({'index': o++ ,'option':'No Answer', 'correct_answer': data[j].tb_question[i].opt_a, 'id_user': id_user, 'id_question': data[j].tb_question[i].id});
                    const answer = [data[j].tb_question[i].opt_a, data[j].tb_question[i].opt_b, data[j].tb_question[i].opt_c, data[j].tb_question[i].opt_d]
                    const newAnswer = answer.slice()
                    for (let k = newAnswer.length - 1; k > 0; k--) {
                        const rand = Math.floor(Math.random() * (k + 1));
                        [newAnswer[k], newAnswer[rand]] = [newAnswer[rand], newAnswer[k]];
                    }
                    data[j].tb_question[i].answer = newAnswer;
                    data[j].tb_question[i].correct_answer = data[j].tb_question[i].opt_a;
                };
            }
            setListeningAnswer(tempListening)
            setListeningQuestion(data);
        })
    }

    const getArrangeQuestion = () => {
        URL.API_URL.get('arrangeQuestion')
        .then(res=>{
            const data = res.data.data
            const newData = data.slice()

            for (let i = data.length - 1; i > 0; i--) {
                const rand = Math.floor(Math.random() * (i + 1));
                [newData[i], newData[rand]] = [newData[rand], newData[i]];
            }
            setArrangeQuestion(newData);
            setArrangeAnswer(data);
        })
    }

  return (
    <>
    <div className="container">
        <div className="header">
            <p>NIEC English Course</p>
            <h1>Placement Test</h1>
        </div>
        { showStructure && (
            <StructureQuestion
            structureQuestion1={structureComplete1}
            structureQuestion2={structureWrong}
            structureQuestion3={structureComplete2}
            structureAnswer={structureAnswer}
            handleStructureAnswers={handleStructureAnswers}
            />
        )            
        }
        { showReading && (
            <ReadingQuestion 
                handleReadingAnswers={handleReadingAnswers}
                readingQuestion={readingQuestion} 
                arrangeQuestion={arrangeQuestion}
                arrangeAnswer={arrangeAnswer}
                setArrangeQuestion={setArrangeQuestion}
                readingAnswer={readingAnswer}/>
        )
        }
        { showListening && (
            <ListeningQuestion 
            handleListeningAnswers={handleListeningAnswers} 
            listeningQuestion={listeningQuestion} 
            // submitListening={submitListening}
            listeningAnswer={listeningAnswer}
            isMobile={isMobile} />
        )
        }
        { showWriting && (
            <WritingQuestion 
            writingQuestion={writingQuestion}
            handleWritingAnswer={handleWritingAnswer}
            writingAnswer={writingAnswer}
        />
        )
        }  
        {/* {isMobile && (
            <div className="btn-wrapp">
                <button disabled={loading ? true : false} className={`btn btn-submit-test reverse`} onClick={handleClick}>Submit Test</button>
            </div>
        )}   */}
    </div>
    {/* {!isMobile && ( */}
        <div className="btn-wrapper">
            <div className="row h-100">
                <div className="col-lg-8 btn-navigation">
                    <button onClick={handleShowStructure} className={`btn btn-nav-test ${showStructure && 'showing'}`}>Structure</button>
                    <button onClick={handleShowReading} className={`btn btn-nav-test ${showReading && 'showing'}`}>Reading</button>
                    <button onClick={handleShowListening} className={`btn btn-nav-test ${showListening && 'showing'}`}>Listening</button>
                    <button onClick={handleShowWriting} className={`btn btn-nav-test ${showWriting && 'showing'}`}>Writing</button>
                </div>
                <div className="col-lg-4 btn-submit">
                    <button disabled={loading ? true : false} className='btn btn-submit-test' onClick={handleClick}>Submit Test</button>
                </div>
            </div>
        </div>
    {/* )} */}
    </>
  )
}
