import React from 'react'
import { ListeningQuestions } from './ListeningQuestions'

const ListeningQuestion = ({listeningQuestion, handleListeningAnswers, listeningAnswer, isMobile}) => {
    let ui = 1
    let k = 0
  return (
    <div className="listening-question card-question">
        <h5 className='title-question'>Listening Test</h5>
        <p><span className='question-direction'><span className='bold'>Directions: </span> Please play the audio clip by number and then choose the correct answer.</span></p>
            <form action="">
            {
                listeningQuestion?.map((q, i) => {
                    return (
                        <>
                        <div className="question-list question-listening">
                            <div className="row">
                                <div className="col-lg-4 col-md-12">
                                    <div className="audio-question">
                                        <audio controls controlsList="nodownload" className='audio-listening'>
                                            <source src={q.audio_question} type="audio/mp3" />
                                        </audio>
                                        {/* {q.audio_question} */}
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-12">
                                    <div className="row">
                                {q.tb_question?.map((t, j) => {
                                    return(
                                        <ListeningQuestions key={j}
                                        question={t.question}
                                        answer={t.answer} 
                                        correct_answer={t.correct_answer} 
                                        handleListeningAnswers={handleListeningAnswers}
                                        listeningAnswer={listeningAnswer}
                                        isMobile={isMobile}
                                        k={k++}/>
                                        )
                                    })
                                }  
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        </>
                    )
                })
            }
            </form>
        </div>
            
  )
}

export default ListeningQuestion
