import React from 'react'
import SimpleMDE from "react-simplemde-editor";
// import { SimpleMdeReact } from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";


const WritingQuestion = ({writingQuestion, handleWritingAnswer, writingAnswer}) => {
// console.log(writingAnswer)
  return (
    <div className="border card-question">
        <h5 className='title-question'>Writing Test</h5>
        <p><span className='question-direction'><span className='bold'>Directions: </span> Write a short essay to answer the question regarding the topic shown below.Your essay should be in a formal style, at least 150 words in length.</span></p>
        <h5>{writingQuestion}</h5>
    
        <SimpleMDE
          id="editor_container"
          value={writingAnswer.option}
          onChange={(e) => handleWritingAnswer(e)}
        />
    </div>
  )
}

export default WritingQuestion