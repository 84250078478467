import React from 'react'

export const ReadingQuestions = ({answer, correct_answer, readingAnswer, handleReadingAnswers, question, ui, k}) => {
  return (
    <div className="question-list">
      <div className="row">
        <div className="col-lg-12 col-md-12">
        <table className='table table-borderless'>
            <tbody>
                <tr key={ui}>
                    <td width={20} className='bold'>{ui++ + '. '}</td>
                    <td>{question}</td>
                </tr>
            </tbody>
        </table>    
        </div>
        <div className="col-lg-12 col-md-12">
        <select defaultValue={readingAnswer[k]?.option} className='form-select form-select-sm answer-question mx-auto' name='option' id="" onChange={(e)=> handleReadingAnswers(e, correct_answer, k)}>
              <option value="">Choose answer</option>
              {answer?.map((a, k)=> (<option key={k}  value={a}>{a}</option>))}
          </select>
        </div>
      </div>
    </div>
  )
}
