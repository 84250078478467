import React, { useEffect, useState } from 'react'
import { ReadingQuestions } from './ReadingQuestions';
import { Container, Draggable } from 'react-smooth-dnd';
import ResizePanel from "react-resize-panel";

const ReadingQuestion = ({readingQuestion, handleReadingAnswers, readingAnswer, arrangeQuestion, arrangeAnswer, setArrangeQuestion}) => {
   let  ui =1;
   let k = 0;
   const [isMobile, setIsMobile] = useState(true)
   const [showParagraph, setShowParagraph] = useState(true)
 
    // create an event listener
    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)
    })

    const handleResize = () => {
        if (window.innerWidth <= 426) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    const handleParagraph = (e) => {
        e.preventDefault()
        setShowParagraph(!showParagraph)
    }

   const applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;
  
    const result = [...arr];
    let itemToAdd = payload;
  
    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }
  
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
    setArrangeQuestion(result)
    // return result;
  };
  return (
    <div className="reading-question border card-question">
        <h5 className='title-question'>Reading Test</h5>
        {isMobile && (<p><span className='question-direction'><span className='bold'>Directions: </span> Read the paragraph below.</span></p>)}
        <form action="">
    {
        readingQuestion?.map((q, i) => {
            return (
                <>
                { !isMobile && (
                    <div
                    style={{
                    height: "500px",
                    display: "flex",
                    flexDirection: "row",
                    overflow: "hidden"
                    }}
                >
                    <ResizePanel
                    direction="e"
                    style={{background: '#fff', width: "100%" }}
                    >
                    <div style={{overflow: 'auto', border: "1px solid #000" }}>
                        <div style={{padding: '10px', }}>
                        <p><span className='question-direction'><span className='bold'>Directions: </span> Read the paragraph below.</span></p>
                        <div key={i} className="text-question text-center">
                            <img src={q.img_file} alt="paragraph-img" width={350} style={{margin: 'auto'}}/>
                            <div dangerouslySetInnerHTML={{__html: q.text_question}} />
                        </div>
                        </div>
                    </div>
                    </ResizePanel>
                    <div style={{ flexGrow: "1", overflow: 'auto', border: "1px solid #000" }}>
                        <div style={{padding: '10px'}}>
                        <p><span className='question-direction'><span className='bold'>Direction: </span> Choose the correct answer according to the text above.</span></p>
                        {q.tb_question?.map((t, j) => {
                        return(
                            <ReadingQuestions key={j}
                            question={t.question} 
                            answer={t.answer} 
                            correct_answer={t.correct_answer} 
                            handleReadingAnswers={handleReadingAnswers}
                            arrangeQuestion={arrangeQuestion}
                            readingAnswer={readingAnswer}
                            ui={ui++}
                            k={k++} />                    
                        )
                    })}
                        </div>
                    </div>
                </div>
                )
                }
                {/*  */}
                {isMobile && (
                    <>
                    <div key={i} className="text-question text-center">
                        <button onClick={handleParagraph} class="btn btn-nav-test">{showParagraph ? 'Hide' : 'Show'} Paragraph</button><br />
                    { showParagraph && (
                        <><img src={q.img_file} alt="paragraph-img" width={350} style={{margin: 'auto'}}/>
                        <div dangerouslySetInnerHTML={{__html: q.text_question}} /></>
                    )
                    }
                    </div>
                    <p><span className='question-direction'><span className='bold'>Direction: </span> Choose the correct answer according to the text above.</span></p>
                            {q.tb_question?.map((t, j) => {
                            return(
                                <ReadingQuestions key={j}
                                question={t.question} 
                                answer={t.answer} 
                                correct_answer={t.correct_answer} 
                                handleReadingAnswers={handleReadingAnswers}
                                arrangeQuestion={arrangeQuestion}
                                readingAnswer={readingAnswer}
                                ui={ui++}
                                k={k++} />                
                            )
                        })}
                    </>
                )
                }
                </>
            )
        })
    }
        <hr />
        <p><span className='question-direction'><span className='bold'>Directions: </span> Arrange the jumbled paragraphs below into an organized text.</span></p>
        <div className="dnd" style={{marginBottom: '70px'}}>
            <Container onDrop={e => applyDrag(arrangeQuestion, e)}>
                {arrangeQuestion.map((item, i) => {
                    return (
                    <Draggable key={item.id}>
                        <p key={i} className='box-question-arrange'>{item.text_arrange}</p>
                    </Draggable>
                    )
                })}
            </Container>
        </div> 
        </form> 
    </div>        
  )
}

export default ReadingQuestion