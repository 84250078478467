const FormValidation = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.name) {
        errors.name = "Nama Lengkap Wajib Diisi"
    }
    if (!values.phone) {
        errors.phone = "No Telepon Wajib Diisi"
    }
    if (!values.email) {
      errors.email = "Email Wajib diisi";
    } else if (!regex.test(values.email)) {
      errors.email = "Email Invallid";
    }
    
    return errors;
}

export default FormValidation