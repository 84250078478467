import React, { useState } from 'react'

const StructureQuestion = ({structureQuestion1, structureQuestion2, structureQuestion3, handleStructureAnswers, structureAnswer}) => {
    let q1 = 1;
    let q2 = structureQuestion1.length ;
    let q3 = structureQuestion1.length + structureQuestion2.length;
  return (
    <div className="structure-question border card-question">
        <h5 className='title-question'>English Structure</h5>

{/* --------Complete Sentence----------------------------------------------------------------------------------------------------------------------------- */}
        <form action="">
        <div className="structure-part">
        <p><span className='question-direction'><span className='bold'>Direction: </span> Choose the correct answer to complete the sentences below.</span></p>
        {
        structureQuestion1?.map((q, i) => {
            let j = q1++;
            let c = j - 1
            return (  
                <div key={i} className="question-list">
                    <div className="row">
                    <div className="col-lg-10">
                    <table className='table table-borderless'>
                        <tbody>
                            <tr key={i}>
                                <td width={20} className='bold'>{j + '. '}</td>
                                <td>{q.question}</td>
                            </tr>
                        </tbody>
                    </table>    
                    </div>
                    <div className="col-lg-2">
                    <select defaultValue={structureAnswer[c]?.option} className='form-select form-select-sm answer-question' name='option' id="" onChange={(e)=> handleStructureAnswers(e, q.correct_answer, c)}>
                         <option value="">Choose answer</option>
                         {q.answers.map((a, j)=> (<option key={j}  value={a}>{a}</option>))}
                     </select>
                    </div>
                </div>
                </div>
            )
        })
        }
        </div>
        <hr />
        <div className="structure-part">
        <p><span className='question-direction'><span className='bold'>Direction: </span> Choose the incorrect word in each of the sentences below.</span></p>
        {   
        structureQuestion2?.map((q, i) => {
            let j = q2++ + 1;
            let c = j - 1;
            return (    
                <div key={i} className="question-list">
                    <div className="row">
                    <div className="col-lg-10">
                    <table className='table table-borderless'>
                        <tbody>
                            <tr key={i}>
                                <td width={20} className='bold'>{j + '. '}</td>
                                {/* <td>{q.question}</td> */}
                                <td><div dangerouslySetInnerHTML={{__html: q.question}}></div></td>
                            </tr>
                        </tbody>
                    </table>    
                    </div>
                    <div className="col-lg-2">
                    <select defaultValue={structureAnswer[c]?.option} className='form-select form-select-sm answer-question' name='option' id="" onChange={(e)=> handleStructureAnswers(e, q.correct_answer, c)}>
                         <option value="">Choose answer</option>
                         {q.answers.map((a, j)=> (<option key={j}  value={a}>{a}</option>))}
                     </select>
                    </div>
                </div>
                </div>
            )
        })
        }
        </div>
        <hr />
        <div className="structure-part">
        <p><span className='question-direction'><span className='bold'>Direction: </span> Choose the correct answer to complete the sentences below.</span></p>
        {
        structureQuestion3?.map((q, i,) => {
            let j = q3++ + 1;
            let c = j - 1;
            return (
                <div key={i} className="question-list">
                    <div className="row">
                    <div className="col-lg-10">
                    <table className='table table-borderless'>
                        <tbody>
                            <tr key={i}>
                                <td width={20} className='bold'>{j + '. '}</td>
                                <td>{q.question}</td>
                            </tr>
                        </tbody>
                    </table>    
                    </div>
                    <div className="col-lg-2">
                    <select defaultValue={structureAnswer[c]?.option} className='form-select form-select-sm answer-question' name='option' id="" onChange={(e)=> handleStructureAnswers(e, q.correct_answer, c)}>
                         <option value="">Choose answer</option>
                         {q.answers.map((a, j)=> (<option key={j}  value={a}>{a}</option>))}
                     </select>
                    </div>
                </div>
                </div>
            )
        })
        }
        </div>
        </form> 
    </div>
  )
}

export default StructureQuestion