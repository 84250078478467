import axios from 'axios';

// const url = 'http://103.41.205.87/placement-test/';
const url = 'https://superstudent.niecindonesia.com/placement-test/'; 
const application = 'https://superstudent.niecindonesia.com/api/v1/application';

const API_URL = axios.create({
    baseURL: url
  });

  const urlApplication = axios.create({
    baseURL: application
  });

  API_URL.interceptors.request.use(
    (config) => {
      const token = 'x9UMxaGolsflds3yKHiQ7i04whX1WhLOK0KxIDMz3ThQtvDsFpQgpUwTLRUTXKbXgH'
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

const URL = {
  API_URL,
  urlApplication
}

export default URL;