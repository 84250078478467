import React from 'react'

export const ListeningQuestions = ({answer,question, correct_answer, handleListeningAnswers, isMobile, listeningAnswer, ui, k}) => {
    return (
        <div className="col-lg-4">
            <div className="listening-answer-container">
                <div className="number bold">
                    {k + 1}.
                </div>
                <div className="listening-answer">
                <p>{question}</p>
                <select defaultValue={listeningAnswer[k]?.option} className='form-select form-select-sm answer-question' id="" onChange={(e) => handleListeningAnswers(e, correct_answer, k)} >
                    <option value="">Choose Answer</option>
                        {answer?.map((a, h)=> (<option key={h}  value={a}>{a}</option>))}
                </select>
            {
                isMobile &&
                <hr />
            }
                </div>
            </div>
        </div>
    )
  }