import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PlacementTest } from './pages/PlacementTest';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import PlacementResult from './pages/PlacementResult';
import PlacementHome from './pages/PlacementHome';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    // <App />
    <Router>
      <Routes>
        <Route path='/' element={<PlacementHome/>} exact/>
        <Route path='/placement-test' element={<PlacementTest/>}/>
        <Route path='/placement-result' element={<PlacementResult/>}/>
      </Routes>
    </Router>
    
  // {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
